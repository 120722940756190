import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useTokensStore = defineStore('tokens', () => {
    const initialTokens: Tokens = {
        accessToken: null,
        uid: null,
        client: null
    }

    const appName = import.meta.env.APP_NAME

    const token = useStorage(appName + '-token', '') // node api

    const tokens = useStorage(appName + '-tokens', Object.assign({}, initialTokens))

    const store = (headers: any) => {
        tokens.value = {
            accessToken: headers['access-token'],
            client: headers.client,
            uid: headers.uid
        }
    }
    const storeNodeAPIToken = (headers: any) => {
        if (headers.token) {
            token.value = headers.token
        }
    }

    const reset = () => {
        token.value = ''
        tokens.value = initialTokens
    }

    return {
        token,
        tokens,
        storeTokens: store,
        storeNodeAPIToken,
        reset
    }
})

interface Tokens {
    accessToken: string | null
    client: string | null
    uid: string | null
}
