import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import dayjs from 'dayjs'
import NProgress from 'nprogress'
import { useAuthStore, useCurrentStore } from '@/stores'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/home'
    },

    {
        // link to change agency
        path: '/agencies/:agencyId/:path*',
        redirect: (to) => {
            if (to.meta.requiresAuth && !useAuthStore().isLoggedIn) {
                return { path: '/sign-in', query: { redirect: to.fullPath } }
            }
            const path =
                '/' + (Array.isArray(to.params.path) ? (to.params.path as string[]).join('/') : '')
            useCurrentStore().setAgencyId(to.params.agencyId as string)
            return path
        }
    },
    {
        path: '/download',
        component: () => import('@/pages/download/Download.vue'),
        meta: {
            layout: 'Centered'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/user/Profile.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/organisation/help',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        meta: {
            layout: 'Organization',
            requiresAuth: true,
            sidebar: false
        },
        children: [
            {
                path: '',
                name: 'organisation-help',
                component: () => import('@/pages/support/Help.vue')
            }
        ]
    },
    {
        path: '/organization/agencies',
        name: 'account-agencies',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        meta: {
            layout: 'Organization',
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'agencies',
                components: {
                    default: () => import('@/pages/organization/agencies/Home.vue'),
                    secondarySidebar: () => import('@/pages/organization/agencies/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: 'create',
                name: 'agency-create',
                components: {
                    default: () => import('@/pages/organization/agencies/CreateEdit.vue'),
                    secondarySidebar: () => import('@/pages/organization/agencies/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@ezio/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/organization/agencies/Nav.vue')
                },
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'agency',
                        component: () => import('@/pages/organization/agencies/Show.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'edit',
                        name: 'agency-edit',
                        components: {
                            default: () => import('@/pages/organization/agencies/CreateEdit.vue'),
                            secondarySidebar: () => import('@/pages/organization/agencies/Nav.vue')
                        },
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'kbis',
                        name: 'kbis',
                        component: () => import('@/pages/organization/agencies/Kbis.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'wire-transfer',
                        name: 'wire-transfer',
                        component: () => import('@/pages/organization/agencies/Wiretransfer.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'onboard/user/create',
                        name: 'agency-onboard-user-create',
                        component: () => import('@/pages/organization/agencies/onboard/User.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'onboard/users/:userId',
                        name: 'agency-onboard-user',
                        component: () => import('@/pages/organization/agencies/onboard/User.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/organization/invoices',
        name: 'account-invoices',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        meta: {
            layout: 'Organization',
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'organisation-invoices',
                components: {
                    default: () => import('@/pages/organization/invoices/Home.vue'),
                    secondarySidebar: () => import('@/pages/organization/invoices/Nav.vue')
                },
                meta: { requiresAuth: true }
            }
        ]
    },

    {
        path: '/home',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/pages/home/Home.vue'),
                meta: {
                    requiresAuth: true,
                    sidebar: false
                }
            }
        ]
    },
    {
        path: '/transactions',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        children: [
            {
                path: '',
                name: 'transactions',
                component: () => import('@/pages/transactions/Index.vue'),
                meta: {
                    requiresAuth: true,
                    displaysMonthlyTransactions: true,
                    sidebar: false
                }
            },
            {
                path: ':id',
                name: 'transaction',
                component: () => import('@/pages/transactions/Show.vue'),
                meta: {
                    requiresAuth: true,
                    sidebar: false
                }
            }
        ]
    },
    {
        path: '/cards',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),

        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'cards',
                components: {
                    default: () => import('@/pages/cards/Home.vue'),
                    secondarySidebar: () => import('@/pages/cards/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@ezio/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/cards/Nav.vue')
                },
                children: [
                    {
                        path: '',
                        name: 'card',
                        component: () => import('@/pages/cards/Show.vue'),
                        meta: {
                            requiresAuth: true,
                            displaysMonthlyTransactions: true
                        }
                    }
                ]
            }
        ]
    },

    {
        path: '/clients',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),

        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'clients',
                components: {
                    default: () => import('@/pages/clients/Home.vue'),
                    secondarySidebar: () => import('@/pages/clients/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: 'create',
                name: 'client-create',
                components: {
                    default: () => import('@/pages/clients/CreateEdit.vue'),
                    secondarySidebar: () => import('@/pages/clients/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@ezio/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/clients/Nav.vue')
                },
                children: [
                    {
                        path: '',
                        name: 'client',
                        component: () => import('@/pages/clients/Show.vue'),
                        meta: {
                            requiresAuth: true,
                            displaysMonthlyTransactions: true,
                            displaysMonthlyPayins: true
                        }
                    },
                    {
                        path: 'edit',
                        name: 'client-edit',
                        component: () => import('@/pages/clients/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'mandate',
                        name: 'client-mandate',
                        component: () => import('@/pages/clients/operations/mandate/Show.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'mandate/create',
                        name: 'mandate-create',
                        component: () => import('@/pages/clients/operations/mandate/Create.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'deposit',
                        name: 'client-deposit',
                        component: () => import('@/pages/clients/operations/Deposit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'deposits/refund',
                        name: 'client-refund',
                        component: () => import('@/pages/clients/operations/Refund.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'guests/create',
                        name: 'client-guest-create',
                        component: () => import('@/pages/clients/guests/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'guests/:guestId/edit',
                        name: 'client-guests-edit',
                        component: () => import('@/pages/clients/guests/CreateEdit.vue'),
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    },

    {
        path: '/employees',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'employees',
                components: {
                    default: () => import('@/pages/employees/Home.vue'),
                    secondarySidebar: () => import('@/pages/employees/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: 'create',
                name: 'employee-create',
                components: {
                    default: () => import('@/pages/employees/CreateEdit.vue'),
                    secondarySidebar: () => import('@/pages/employees/Nav.vue')
                },
                meta: { requiresAuth: true }
            },
            {
                path: ':id',
                components: {
                    default: () => import('@ezio/ui/layouts/RouterViewWrapper.vue'),
                    secondarySidebar: () => import('@/pages/employees/Nav.vue')
                },
                children: [
                    {
                        path: '',
                        name: 'employee',
                        component: () => import('@/pages/employees/Show.vue'),
                        meta: {
                            requiresAuth: true,
                            displaysMonthlyTransactions: true
                        }
                    },
                    {
                        path: 'edit',
                        name: 'employee-edit',
                        components: {
                            default: () => import('@/pages/employees/CreateEdit.vue'),
                            secondarySidebar: () => import('@/pages/employees/Nav.vue')
                        },
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    },
    {
        path: '/bank',
        redirect: '/bank/bank-operations',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        children: [
            {
                path: 'bank-operations',
                name: 'bank-operations',
                components: {
                    default: () => import('@/pages/bank-account/BankOperations.vue'),
                    secondarySidebar: () => import('@/pages/bank-account/Nav.vue')
                }
            },
            {
                path: 'bank-account',
                name: 'bank-account',
                components: {
                    default: () => import('@/pages/bank-account/BankAccount.vue'),
                    secondarySidebar: () => import('@/pages/bank-account/Nav.vue')
                }
            },
            {
                path: 'bank-reports',
                name: 'bank-reports',
                components: {
                    default: () => import('@/pages/bank-account/BankReports.vue'),
                    secondarySidebar: () => import('@/pages/bank-account/Nav.vue')
                }
            },
            {
                path: 'bank-payins',
                name: 'bank-payins',
                components: {
                    default: () => import('@/pages/bank-account/BankPayins.vue'),
                    secondarySidebar: () => import('@/pages/bank-account/Nav.vue')
                },
                meta: {
                    displaysMonthlyPayins: true
                }
            }
        ]
    },

    {
        path: '/help',
        component: () => import('@ezio/ui/layouts/DashboardMain.vue'),
        meta: { requiresAuth: true, sidebar: false },
        children: [
            {
                path: '',
                name: 'help',
                component: () => import('@/pages/support/Help.vue')
            }
        ]
    },
    {
        path: '/sign-in',
        name: 'signIn',
        component: () => import('@/pages/auth/SignIn.vue'),
        meta: {
            layout: 'Centered'
        }
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: () => import('@/pages/auth/SignUp.vue'),
        meta: {
            layout: 'Centered'
        }
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/pages/auth/PasswordReset.vue'),
        meta: {
            layout: 'Centered'
        }
    },
    {
        path: '/password-change',
        name: 'password-change',
        component: () => import('@/pages/auth/PasswordChange.vue'),
        meta: {
            layout: 'Centered'
        }
    },
    {
        path: '/managers/sign-up',
        name: 'managers-signup',
        component: () => import('@/pages/managers/SignUp.vue'),
        meta: {
            layout: 'Centered'
        }
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.path === '/espace-client/') {
        window.location.replace('https://client-space.ezio.app')
    }

    if (!NProgress.isStarted()) {
        NProgress.start()
    }

    if (to.meta.requiresAuth && !useAuthStore().isLoggedIn) {
        next('/sign-in?redirect=' + to.fullPath)
    } else if (
        (to.meta.displaysMonthlyTransactions && !to.query.t_month) ||
        (to.meta.displaysMonthlyPayins && !to.query.p_month)
    ) {
        const month = dayjs().format('YYYY-MM')
        if (to.meta.displaysMonthlyTransactions && !to.query.t_month) {
            to.query.t_month = month
        }
        if (to.meta.displaysMonthlyPayins && !to.query.p_month) {
            to.query.p_month = month
        }

        next(to)
    } else {
        next()
    }
})

router.afterEach(() => {
    NProgress.done()
})

export default router
