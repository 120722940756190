import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useEzioAPI } from '@ezio/services'
import { BaseError } from '@ezio/utils'
import { useAgenciesStore, useAuthStore, useBankStore } from '..'
import { Organization } from '@ezio/features/organizations/types'
import { User } from '@ezio/features/users/types'

export const useCurrentStore = defineStore('current', () => {
    const { api } = useEzioAPI()
    const authStore = useAuthStore()
    const agenciesStore = useAgenciesStore()

    const user = ref<User>(null)
    const organization = ref<Organization>(null)

    const agency = computed(() => {
        return agenciesStore.current
    })
    const agencyId = ref<string>(null)

    const fetch = async () => {
        const response = await authStore.fetch({ include: 'account' })

        if (response?.agencyId?.length) {
            await agenciesStore.fetch(response.agencyId)
        }

        if (response) {
            store(response)
            useBankStore().fetch()
            if (!response.agencyId?.length && agenciesStore.activesByName.length) {
                const r = await setAgencyId(agenciesStore.activesByName[0].id)
                store(r.data)
            }
        }
    }

    const updateUser = async (params: any) => {
        // await api.put('agencies/' + user.agencyId + '/users/' + user.value.id, params)
        // await fetch()
    }

    const setAgencyId = async (id: string) => {
        if (!user.value) {
            await fetch()
        }
        try {
            const response = await api.put('/users/' + user.value.id + '/set_agency_id', {
                agency_id: id,
                include: 'account'
            })
            store(response.data)
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const store = async (data) => {
        agencyId.value = data.agencyId
        if (data.agencyId) {
            agenciesStore.setCurrent(data.agencyId)
        }
        organization.value = Object.assign({}, data.account)
        user.value = Object.assign({}, data)
    }

    const reset = async () => {
        user.value = null
        organization.value = null
    }

    return {
        user,
        agencyId,
        agency,
        organization,
        fetch,
        updateUser,
        store,
        setAgencyId,
        reset
    }
})
