<template>
    <div class="avatar">
        <img v-if="imgSrc" :src="imgSrc" />
        <div v-else v-text="letters" class="letters" :style="'background:' + bgColor + ';color:' + props.textColor" />
    </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, watch } from 'vue'
import defaultImage from '@ezio/assets/images/default-avatar.png'

const props = withDefaults(
    defineProps<{
        src?: string
        text?: string
        color?: string
        textColor?: string
    }>(),
    {
        src: null,
        text: '-',
        color: null,
        textColor: null
    }
)

const imgSrc = ref<string>(null)
const letters = ref<string>(null)
const bgColor = ref<string>(null)
// const backgroundColors = ['violet', 'brown', 'orange', 'blue', 'red', 'green']
const backgroundColors = ['#005051', '#FFBC00', '#FF6B45', '#8E0045', '#007876', '#5C9EAD', '#465775', '#EF6F6C', '#FF7900', '#59C9A5']
onBeforeMount(() => {
    imgSrc.value = null
    letters.value = null
    init()
})

watch(props, () => {
    imgSrc.value = null
    letters.value = null
    init()
})

const init = () => {
    if (props.src) {
        imgSrc.value = props.src
    } else if (props.text) {
        const firstLetter = props.text.charAt(0).toUpperCase()
        if (/\s/g.test(props.text.trim())) {
            const splitText = props.text.split(' ')
            letters.value = firstLetter + splitText[1].charAt(0).toUpperCase()
        } else {
            letters.value = firstLetter
        }

        bgColor.value = props.color || getBgColor()
    } else {
        imgSrc.value = defaultImage
    }
}

const getBgColor = () => {
    let sum = 0
    for (let i = 0; i < props.text.length; i++) {
        sum += props.text.charCodeAt(i)
    }
    return backgroundColors[sum % backgroundColors.length]
}
</script>
<style lang="scss" scoped>
.avatar {
    position: relative;
    width: 30px;
    border: 1px solid var(--c-color-border-soft);
    border-radius: 30%;
    overflow: hidden;

    &:before {
        content: '';
        float: left;
        padding-top: 100%;
    }
    img {
        background: #fff;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}
.letters {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    line-height: 1;
    user-select: none;
    color: #fff;
    padding-top: 1px;
}

.letters-violet {
    background: #706fb3;
}
.letters-brown {
    background: #a17720;
}
.letters-red {
    background: #b54c73;
}
.letters-blue {
    background: #0087a9;
}
.letters-green {
    background: #00865f;
}
.letters-orange {
    background: #ff8847;
}
.clickable {
    cursor: pointer;
}
</style>
