<template>
    <div class="amount">
        <div @click.stop="showOperations">
            <div class="balances">
                <div class="balance">
                    <b>Balance</b>
                    <div
                        :class="{
                            'alert-text': bankStore.wallet?.balances.authorizedBalance < 100
                        }">
                        {{ currency(bankStore.wallet?.balances.authorizedBalance) }}
                    </div>
                </div>
            </div>
            <FeatherIcon name="chevron-down" :class="{ 'reverse-icon': showFloatingBox }" />
        </div>
        <FloatingBox class="bottom p2" v-if="showFloatingBox" @dismiss="showFloatingBox = false">
            <template #content>
                <div class="infos">
                    <BaseElement class="solo">
                        <template #title>
                            <h2>Compte de paiement</h2>
                            <CRouterLink
                                class="border"
                                to="/bank/bank-account"
                                @click="showFloatingBox = false">
                                Relevés / IBAN
                            </CRouterLink>
                        </template>
                    </BaseElement>

                    <BaseTable>
                        <template #title>
                            <h3>À venir</h3>
                            <CRouterLink
                                class="bare"
                                to="/bank/bank-payins"
                                @click="showFloatingBox = false">
                                Tout voir
                            </CRouterLink>
                            <!-- <CButton class="bare" @click="navToBankAccount">Tout voir</CButton> -->
                        </template>
                        <template #keys>
                            <th class="td-date">Date</th>
                            <th class="td-amount">Montant</th>
                        </template>
                        <template #values>
                            <tr v-for="payin in bankStore.pendingPayins">
                                <td>{{ dayjs().to(dayjs(payin.date).endOf('day')) }}</td>
                                <td>+{{ currency(payin.amount) }}</td>
                            </tr>
                        </template>
                        <template #empty v-if="bankStore.pendingPayins.length < 1">
                            Aucune opération à venir
                        </template>
                    </BaseTable>

                    <OperationTable
                        v-if="operations && operations.length"
                        :values="operations"
                        class="operations-table">
                        <template #title>
                            <h3>Dernières opérations</h3>
                            <!-- <CButton class="bare" @click="navToBankAccount">Tout voir</CButton> -->
                            <CRouterLink
                                class="bare"
                                to="/bank/bank-operations"
                                @click="showFloatingBox = false">
                                Tout voir
                            </CRouterLink>
                        </template>
                    </OperationTable>
                    <div v-else class="empty">pas d'operations</div>
                </div>
                <div class="actions">
                    <!-- <CButton class="bare" @click="navToBankAccount">Tout voir</CButton> -->
                </div>
            </template>
        </FloatingBox>
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { defineAsyncComponent, ref, computed, onBeforeMount, watch } from 'vue'
import { currency } from '@ezio/utils'
import { useBankStore, useCurrentStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useWindowSize } from '@vueuse/core'

import OperationTable from '@/pages/bank-account/components/Table.vue'

dayjs.extend(relativeTime)

const BaseElement = defineAsyncComponent(() => import('@ezio/ui/content/BaseElement.vue'))
const BaseTable = defineAsyncComponent(() => import('@ezio/ui/content/BaseTable.vue'))
const FloatingBox = defineAsyncComponent(async () => (await import('@ezio/ui')).FloatingBox)

const currentStore = useCurrentStore()
const bankStore = useBankStore()
const router = useRouter()
const { width } = useWindowSize()

const showFloatingBox = ref(false)

const operations = computed(() => bankStore?.operations?.data.filter((op, idx) => idx < 3))

const showOperations = async () => {
    if (width.value > 799) {
        showFloatingBox.value = !showFloatingBox.value
        if (showFloatingBox.value) {
            await useBankStore().fetchPendingPayins()
            await useBankStore().fetchOperations()
        }
    } else {
        router.push({ name: 'bank-account' })
    }
}

const navToBankAccount = () => {
    showFloatingBox.value = false
    router.push({ name: 'bank-operations' })
}

onBeforeMount(async () => {
    init()
})

watch(
    () => currentStore?.agencyId,
    () => {
        init()
    }
)

const init = async () => {
    if (currentStore?.agencyId) {
        await useBankStore().fetch()
    }
}
</script>
<style scoped lang="scss">
.amount {
    position: relative;

    border-left: 1px solid var(--c-color-border-soft);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    flex-wrap: nowrap;

    .balances {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 5px !important;
    }
    & > div:first-child {
        display: grid;
        grid-template-columns: 1fr 20px;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        user-select: none;
        @media screen and (max-width: 799px) {
            grid-template-columns: 1fr;
        }
        .balance {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: space-between;
            width: 100%;
        }
        .mute {
            color: var(--c-color-text-soft);
        }
    }
    svg {
        transition: transform 0.25s ease-in-out;
        @media screen and (max-width: 799px) {
            display: none;
        }
    }

    .reverse-icon {
        transform: rotate(180deg);
    }
}
.infos {
    max-height: 500px;
    min-width: 400px;
    overflow: scroll;
    th,
    td {
        padding-top: 9px;
        padding-bottom: 9px;
    }
}
.operations-table {
    margin-top: 20px;
}

.actions {
    margin-top: 15px;
}

.empty {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.alert-text {
    color: var(--c-color-alert);
}
</style>
