import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { ActivateAutoConfirmParams, Employee, EmployeeCreate, UpdateEmployeeParams } from '@ezio/features/employees/types'
import { BaseError, objectify } from '@ezio/utils'
import { useEzioAPI } from '@ezio/services'

export const useEmployeesStore = defineStore('employees', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: Employee }> = ref({})
    const active: Ref<Employee[]> = ref([])
    const inactive: Ref<Employee[]> = ref([])

    const fetchCollection = async (
        params = {
            status: 0,
            agency_id: null
        }
    ) => {
        const include = params?.agency_id ? 'card' : ''
        try {
            const payload = Object.assign({}, params, {
                pagination: false,
                include
            })
            const response = await api.get('employees', payload)
            storeIndex(response.data, params)
        } catch (error) {
            //
        }
    }

    const fetch = async (employeeId: string) => {
        try {
            const response = await api.get('employees/' + employeeId, { include: 'card' })
            store([response.data])
            return response.data
        } catch (error) {
            //
        }
    }

    const create = async (params: EmployeeCreate) => {
        try {
            const response = await api.post('employees', params)
            store([response.data])
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const update = async (data: UpdateEmployeeParams) => {
        try {
            const params = Object.assign({}, data)
            const response = await api.put('employees/' + data.id, params)
            store([response.data])
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: UpdateEmployeeParams | EmployeeCreate) => {
        if ('id' in data) {
            return await update(data)
        } else {
            return await create(data)
        }
    }

    const activateAutoConfirm = async (data: ActivateAutoConfirmParams) => {
        return update({
            id: data.employee.id,
            auto_confirm: data.autoConfirm
        })
    }

    const archive = async (data: { employee: Employee }) => {
        try {
            await update({
                id: data.employee.id,
                status: 1
            })
            const index = active.value.findIndex((c) => data.employee.id === c.id)
            active.value.splice(index, 1)
        } catch (error: any) {
            //
        }
    }
    const activate = async (data: { employee: Employee }) => {
        try {
            await update({
                id: data.employee.id,
                // refund_amount: data.refundAmount,
                status: 0
            })
            const index = inactive.value.findIndex((c) => data.employee.id === c.id)
            inactive.value.splice(index, 1)
        } catch (error: any) {
            //
        }
    }

    const addClient = async (employeeId: string, clientId: string) => {
        const response = await api.post('employees/' + employeeId + '/add_client', {
            client_id: clientId
        })
        return response.data
    }
    const removeClient = async (employeeId: string, clientId: string) => {
        const response = await api.post('employees/' + employeeId + '/remove_client', {
            client_id: clientId
        })
        return response.data
    }

    const fetchClients = async (employeeId: string) => {
        const response = await api.get('employees/' + employeeId + '/clients')
        return response.data
    }

    const storeIndex = (employees: Employee[], params: any) => {
        if (params.status === 0) {
            active.value = employees
        }
        if (params.status === 1) {
            inactive.value = employees
        }
    }
    const store = (clients: Employee[]) => {
        all.value = Object.assign({}, all.value, objectify(clients))
    }

    const reset = () => {
        all.value = {}
        active.value = []
        inactive.value = []
    }

    return {
        all,
        active,
        inactive,
        fetchCollection,
        fetch,
        create,
        update,
        createOrUpdate,
        activateAutoConfirm,
        archive,
        activate,
        addClient,
        removeClient,
        fetchClients,
        reset
    }
})
