import { useAgenciesStore } from '@ezio/features/agencies/store'
import { useAlertsStore } from '@ezio/features/alerts/store'
// import { useAuthStore } from '@ezio/features/auth/storeNodeApi'
import { useAuthStore } from '@ezio/features/auth/store'
import { useBankStore } from '@ezio/features/bank/store'
import { useCardsStore } from '@ezio/features/cards/store'
import { useClientsStore } from '@ezio/features/clients/store'
import { useCurrentStore } from './current'
import { useDepositsStore } from '@ezio/features/deposits/store'
import { useEmployeesStore } from '@ezio/features/employees/store'
import { useOrganizationsStore } from '@ezio/features/organizations/store'
import { usePayinsStore } from '@ezio/features/payins/store'
import { usePayoutsStore } from '@ezio/features/payouts/store'
import { useTokensStore } from '@ezio/features/tokens'
import { useTransactionsStore } from '@ezio/features/transactions/store'
import { useUsersStore } from '@ezio/features/users/store'
import { localStores } from '@ezio/features'

const injectLocalStores = () => {
    localStores.currentStore = useCurrentStore()
}

const init = async () => {
    // useBankStore().init()
    useAlertsStore().init()
    // useTransactionsStore().init()
}

const changeAgency = async (agencyId: string) => {
    useAlertsStore().reset()
    useBankStore().reset()
    useCardsStore().reset()
    useClientsStore().reset()
    useDepositsStore().reset()
    useEmployeesStore().reset()
    usePayinsStore().reset()
    usePayoutsStore().reset()
    useTransactionsStore().reset()
    await useCurrentStore().setAgencyId(agencyId)
}

const reset = () => {
    useAgenciesStore().reset()
    useAlertsStore().reset()
    useBankStore().reset()
    useCardsStore().reset()
    useClientsStore().reset()
    useCurrentStore().reset()
    useDepositsStore().reset()
    useEmployeesStore().reset()
    useOrganizationsStore().reset()
    usePayinsStore().reset()
    usePayoutsStore().reset()
    useTokensStore().reset()
    useTransactionsStore().reset()
    useUsersStore().reset()
}

export {
    changeAgency,
    useAgenciesStore,
    useAlertsStore,
    useAuthStore,
    useBankStore,
    useCardsStore,
    useClientsStore,
    useCurrentStore,
    useDepositsStore,
    useEmployeesStore,
    useUsersStore,
    useOrganizationsStore,
    usePayinsStore,
    usePayoutsStore,
    useTransactionsStore,
    useTokensStore,
    init,
    injectLocalStores,
    reset
}
