import { WsConfig } from '@ezio/ws'
import { useTokensStore } from '@ezio/features/tokens'
import { useSocketIo, Socket } from '@ezio/ws'
import { EzioWs } from './types'

const EzioWs = (config: WsConfig) => {
    const socketIo = useSocketIo(config)
    let socket: Socket = null

    const getRequestHeaders = () => {
        const tokensStore = useTokensStore()
        return {
            'Access-Token': tokensStore.tokens.accessToken,
            Client: tokensStore.tokens.client,
            Uid: tokensStore.tokens.uid
        }
    }

    const connect = () => {
        return socketIo.connect(getRequestHeaders())
    }

    const on = (eventName: string, callback: any) => {
        if (!socket) {
            socket = connect()
        }
        return socket.on(eventName, callback)
    }

    const off = (eventName: string) => {
        if (socket) {
            return socket.off(eventName)
        }
    }

    return {
        on,
        off
    }
}

let ws: EzioWs = null

const init = (config: WsConfig) => {
    ws = EzioWs(config)
    return ws
}

export const useEzioWs = () => {
    return {
        ws,
        init
    }
}
