import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { Card, PaymentOnlineParams, UpdateStatusCodeParams, AssignEmployeeParams, RemoveEmployeeParams, UpdateLimitsParams, UpdateOptionsParams, CreateCardsParams, UpdateCardParams, UpdatePinParams } from '@ezio/features/cards/types'
import { BaseError, objectify } from '@ezio/utils'
import { useEzioAPI } from '@ezio/services'

import dayjs from 'dayjs'

export const useCardsStore = defineStore('cards', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: Card }> = ref({})
    const indexByName = ref<Card[]>([])

    const getByLastTransactionDate = computed(() => {
        return indexByName.value.sort((a, b) => {
            if (!a.lastTransactionAt) {
                return 1
            }
            if (!b.lastTransactionAt) {
                return -1
            }
            const dateA = dayjs(a.lastTransactionAt).valueOf()
            const dateB = dayjs(b.lastTransactionAt).valueOf()

            if (dateA < dateB) {
                return 1
            }
            if (dateA > dateB) {
                return -1
            }
            return 0
        })
    })
    const getToOrder = computed(() => {
        const cards = indexByName.value.filter((card: Card) => {
            return card.status === 'created'
        })

        return cards
    })

    const fetchCollection = async (params: any = null) => {
        const include = params?.agency_id ? 'employee,owner' : ''
        try {
            const payload = Object.assign(
                {
                    pagination: false,
                    include
                },
                params
            )
            const response = await api.get('cards', payload)
            storeIndex(response.data)
        } catch (error) {
            //
        }
    }

    const fetch = async (cardId: string) => {
        try {
            const response = await api.get('cards/' + cardId, { include: 'employee,owner' })
            store([response.data])
            return response.data
        } catch (error) {
            //
        }
    }

    const getPin = async (cardId: string, cvc: string): Promise<string> => {
        try {
            const response: any = await api.get('cards/' + cardId + '/pin', {
                cvc
            })
            if (!response) {
                throw new Error('')
            }

            return response.data.pin
        } catch (err) {
            throw new BaseError({
                title: 'Code CVV incorrect',
                message: 'Il semblerait que votre code ne soit pas bon, vous pouvez réessayer.'
            })
        }
    }

    const updatePin = async (cardId: string, data: UpdatePinParams) => {
        try {
            const response = await api.put('cards/' + cardId + '/change_pin', data)
            store([response.data])
        } catch (error: any) {
            throw new BaseError({ title: 'Oups !', message: error.message })
        }
    }

    const unblockPin = async (cardId: string) => {
        try {
            const response = await api.put('cards/' + cardId + '/unblock_pin')
            store([response.data])
        } catch (error: any) {
            throw new BaseError({ title: 'Oups !', message: error.message })
        }
    }

    const create = async (data: CreateCardsParams) => {
        try {
            const response = await api.post('cards', data)
            store(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({ title: 'Oups !', message: error.message })
        }
    }

    const update = async (data: any) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id, params)
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const updateOptions = async (data: UpdateOptionsParams) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id + '/options', params)
            store([response.data])

            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const updateStatusCard = async (data: UpdateOptionsParams) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id + '/options', params)
            store([response.data])

            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const activate = async (cardId: string) => {
        try {
            const response = await api.put('cards/' + cardId + '/activate', { include: 'employee' })
            store([response.data])
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message.error
            })
        }
    }

    const updateLimits = async (data: UpdateLimitsParams) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id + '/limits', params)
            store([response.data])

            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message.error
            })
        }
    }

    const updateOnlinePayments = async (data: PaymentOnlineParams) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id + '/online_payment', params)
            store([response.data])

            return response.data
        } catch (error: any) {
            throw new BaseError({ title: 'Oups !', message: error.message })
        }
    }

    const updateStatusCode = async (data: UpdateStatusCodeParams) => {
        try {
            const params = Object.assign({ include: 'employee' }, data)
            const response = await api.put('cards/' + data.id + '/status_code', params)
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message.error
            })
        }
    }

    const assignEmployee = async (data: AssignEmployeeParams) => {
        return update({
            id: data.card.id,
            employee_id: data.employee.id
        })
    }
    const removeEmployee = async (data: RemoveEmployeeParams) => {
        return update({
            id: data.card.id,
            employee_id: null
        })
    }

    const orderAll = async () => {
        try {
            const response = await api.put('cards/order_all')
            // store([response.data])
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message.error
            })
        }
    }

    const updateIndex = (card: Card) => {
        const temp = indexByName.value.slice()
        const idx = temp.findIndex((c) => card.id === c.id)
        if (idx > -1) {
            temp[idx] = card
        } else {
            temp.push(card)
        }
        temp.sort(sortByName)
        indexByName.value = temp
    }
    const storeIndex = (cards: Card[]) => {
        indexByName.value = cards.sort(sortByName)
    }
    const store = (cards: Card[]) => {
        all.value = Object.assign({}, all.value, objectify(cards))
    }

    const reset = () => {
        all.value = {}
    }

    const sortByName = (A, B) => {
        const nameA = A.cardName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        const nameB = B.cardName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    return {
        all,
        byName: indexByName,
        byLastTransactionDate: getByLastTransactionDate,
        toOrder: getToOrder,
        fetchCollection,
        fetch,
        getPin,
        updatePin,
        unblockPin,
        create,
        update,
        updateOptions,
        updateStatusCard,
        activate,
        updateLimits,
        updateOnlinePayments,
        updateStatusCode,
        assignEmployee,
        removeEmployee,
        orderAll,
        reset
    }
})
