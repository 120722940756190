<template>
    <div class="wrapper" ref="wrapper" @click="isOpen = !isOpen">
        <div>
            <div>
                <avatar
                    class="avatar-mobile"
                    v-if="user"
                    src=""
                    :text="(user?.preferredLastname || user?.lastname) + ' ' + user?.firstname" />
                <avatar class="avatar-mobile" v-else />
                <div class="user">
                    <b>{{ user?.preferredLastname || user?.lastname || 'Nom' }}</b>
                    {{ user?.firstname || 'Prénom' }}
                </div>
            </div>
            <FeatherIcon name="chevron-down" :class="{ 'reverse-icon': isOpen }" />
        </div>

        <Transition name="fade">
            <div class="sub-menu" v-if="isOpen">
                <CRouterLink class="bare" to="/profile">Profil</CRouterLink>
                <CButton class="bare" @click="signOut">Déconnexion</CButton>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { useCurrentStore, useTokensStore } from '@/stores'
import { computed, ComputedRef, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import router from '@/router'

const isOpen = ref(false)

const user: ComputedRef<any> = computed(() => useCurrentStore().user)

const wrapper = ref(null)
onClickOutside(wrapper, () => {
    isOpen.value = false
})

const signOut = () => {
    useTokensStore().reset()
    router.replace('/')
}
</script>
<style lang="scss" scoped>
.wrapper {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;

    padding: 10px;

    border: 1px solid var(--c-color-border-soft);
    border-radius: var(--c-border-radius);
    @media screen and (max-width: 799px) {
        border: 0;
    }
    & > div:first-child {
        display: grid;
        grid-template-columns: 1fr 20px;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        user-select: none;
        @media screen and (max-width: 799px) {
            grid-template-columns: 1fr;
        }
        div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            @media screen and (max-width: 799px) {
                justify-content: center;
            }
        }
        @media screen and (max-width: 799px) {
            svg {
                display: none;
            }
        }
    }
}
.user {
    @media screen and (max-width: 799px) {
        display: none !important;
    }
}
:deep(.avatar-mobile) {
    @media screen and (max-width: 799px) {
        & > span {
            width: 45px;
            height: 45px;
            font-size: 1rem;
        }
    }
}
.sub-menu {
    position: absolute;
    top: 100%;
    right: 0;

    padding: 10px 15px;

    border: 1px solid var(--c-color-border-soft);
    border-radius: var(--c-border-radius);

    background: #fff;

    a,
    button {
        padding: 4px;
        text-align: right;
        color: var(--color-text-hard);
        width: 100%;
    }
}

svg {
    transition: transform 0.25s ease-in-out;
}

.reverse-icon {
    transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
