<template>
    <router-link v-bind="attrs" class="c-button">
        <slot />
    </router-link>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue'
const attrs = useAttrs()
</script>

<style lang="scss" scoped>
.c-button {
    display: block;
    padding: 10px;
    background: var(--c-color-primary);
    border: 0;
    border-radius: var(--c-border-radius);
    color: #fff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
}
.border {
    background: #fff;
    color: var(--c-color-primary);
    border: 1px solid var(--c-color-primary);
}
.simple {
    background: #fff;
    color: var(--c-color-primary);
    border: none;
}
.bare {
    background: #fff;
    color: var(--c-color-primary);
    border: none;
    padding: 0;
    text-align: left;
    span {
        text-decoration: none !important;
    }
    &:hover {
        text-decoration: underline;
    }
}
</style>
