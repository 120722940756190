<template>
    <div class="dashboard-page">
        <header class="header">
            <CurrentAgency v-if="loaded" layout="organization" />
            <div></div>
            <div></div>

            <div v-if="loaded" class="right">
                <CurrentUser />
                <router-link :to="{ name: 'organisation-help' }">
                    <FeatherIcon name="help-circle" />
                </router-link>
            </div>
        </header>

        <section class="sidebar">
            <nav class="nav">
                <router-link to="/organization/agencies">
                    <img src="@ezio/assets/images/main-nav/etablissement-on.svg" class="on" />
                    <img src="@ezio/assets/images/main-nav/etablissement-off.svg" class="off" />
                    <span>Établissements</span>
                </router-link>
                <router-link to="/organization/invoices">
                    <img src="@ezio/assets/images/main-nav/facturation-on.svg" class="on" />
                    <img src="@ezio/assets/images/main-nav/facturation-off.svg" class="off" />
                    <span>Facturation</span>
                </router-link>
            </nav>
            <div class="logo">
                <img src="https://ezio.app/wp-content/uploads/2021/07/Logo-EZIO.png" alt="" />
            </div>
        </section>

        <router-view />
    </div>
</template>
<script lang="ts" setup>
import CurrentAgency from './components/CurrentAgency.vue'
import CurrentUser from '@/layouts/components/CurrentUser.vue'
import { onBeforeMount, ref } from 'vue'
import { useCurrentStore } from '@/stores'

const loaded = ref(false)

onBeforeMount(async () => {
    await useCurrentStore().fetch()
    loaded.value = true
})
</script>
<style lang="scss" scoped>
.dashboard-page {
    display: grid;
    grid-template-columns: 130px 1fr;
    grid-template-rows: auto 1fr;

    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 930px) {
        grid-template-rows: auto auto 1fr;
        grid-template-columns: 1fr;
    }
}
.header {
    grid-column: 1 / -1;
    position: relative;
    z-index: 3;
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding: 20px 50px;
    background: #fff;
    border-bottom: 1px solid var(--c-color-border-hard);
    .right {
        display: inline-flex;
        align-items: center;
        .feather {
            color: var(--c-color-text-hard);
            font-size: 16px;
            margin-left: 14px;
        }
    }
    @media screen and (max-width: 930px) {
        padding: 10px 20px;
    }
}

.sidebar {
    position: relative;
    z-index: 2;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo {
        img {
            display: block;
            width: 100%;
            padding: 12px;
        }
        @media screen and (max-width: 930px) {
            display: none;
        }
    }
}

.nav {
    a {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 23px 50px 20px;
        border-bottom: 1px solid var(--c-color-border-hard);
        border-right: 0px solid var(--c-color-primary);

        color: var(--c-color-primary);

        font-size: 0.8rem;
        text-decoration: none;
        transition: all 0.2s;

        // svg {
        //     min-width: 28px;
        //     font-size: 1.4rem;
        //     margin-bottom: 7px;
        // }
        img {
            width: 34px;
            margin-bottom: 6px;
        }
        .on {
            display: none;
        }

        &.router-link-active,
        &:hover {
            color: var(--c-color-alert);
            background: #fff;
            border-right-width: 6px;

            .on {
                display: block;
            }
            .off {
                display: none;
            }
        }
    }
    @media screen and (max-width: 930px) {
        display: flex;
        justify-content: space-evenly;
        padding: 0 20px;

        a {
            padding: 10px 10px 2px;
            border-right: 0;
            border-bottom: 0px solid var(--c-color-primary);

            &.router-link-active,
            &:hover {
                color: var(--c-color-text-hard);
                background: #fff;
                border-bottom-width: 2px;
                border-right-width: 1px;
            }
            span {
                display: none;
            }
        }
    }
}
</style>
