import { Ref, ref } from 'vue'
import { BaseError, objectify } from '@ezio/utils'
import { defineStore } from 'pinia'
import { useEzioAPI } from '@ezio/services'
import { Payin, PayinCreateParams } from '@ezio/features/payins/types'
import dayjs from 'dayjs'

export const usePayinsStore = defineStore('payins', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: Payin }> = ref({})
    const byMonth: Ref<{ [key: string]: string[] }> = ref({})
    // const pagination = ref<Pagination>()

    const getByMonth = (params: { month: string }) => {
        return byMonth.value[params.month] ? byMonth.value[params.month].map((id) => all.value[id]) : []
    }
    const getSddeByMonth = (params: { month: string }) => {
        return getByMonth(params).filter((payin) => payin.payinMethod === 'direct_debit')
    }
    const getSctrByMonth = (params: { month: string }) => {
        return getByMonth(params).filter((payin) => payin.payinMethod === 'wiretransfer')
    }

    const hasPayin = async (agencyId: string) => {
        return !!(await usePayinsStore().fetchCollection({ agency_id: agencyId, payin_method: 'wiretransfer' })).length
    }

    const fetchCollection = async (data: any): Promise<Payin[]> => {
        const params = Object.assign({}, data, {
            pagination: false,
            include: 'transactions,client,alerts' + (data.agency_id === 'all' ? ',agency' : '')
        })
        const route = params.agency_id === 'all' ? 'payins' : 'agencies/' + params.agency_id + '/payins'

        if (params.agency_id === 'all') {
            params.agency_id = null
        }

        const response = await api.get(route, params)

        store(response.data)

        const month = data.start_at ? dayjs(data.start_at).format('YYYY-MM') : 'all'
        byMonth.value = Object.assign({}, byMonth.value, {
            [month]: response.data.map((p: Payin) => p.id)
        })

        return response.data
    }

    const fetch = async (id: Payin['id']) => {
        const response = await api.get('payins/' + id, { include: 'transactions,client,agency,alerts,recalls' })
        store([response.data])
    }

    const create = async (data: PayinCreateParams): Promise<Payin> => {
        try {
            const response = await api.post('agencies/' + data.agency_id + '/payins', data)

            store([response.data])
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const accept = async (payinId: string) => {
        try {
            const response = await api.put('payins/' + payinId + '/accept', { include: 'transactions,client,alerts,agency' })
            store([response.data])
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const refuse = async (payinId: string) => {
        try {
            const response = await api.put('payins/' + payinId + '/refuse', { include: 'transactions,client,alerts,agency' })
            store([response.data])
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const answerRecall = async (recallId: string, params) => {
        try {
            const response = await api.put('recalls/' + recallId, {
                params,
                include: 'transactions,client,alerts,agency'
            })
            store([response.data])
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    // const storeCollection = (payins: Payin[]) => {
    //     all.value = objectify(payins, { __typename: 'Payin' })
    // }

    const store = (payins: Payin[]) => {
        all.value = Object.assign({}, all.value, objectify(payins, { __typename: 'Payin' }))
    }

    const reset = () => {
        all.value = {}
        byMonth.value = {}
    }
    const resetMonths = () => {
        byMonth.value = {}
    }

    return {
        all,
        // pagination,
        byMonth: getByMonth,
        sddeByMonth: getSddeByMonth,
        sctrByMonth: getSctrByMonth,
        fetchCollection,
        hasPayin,
        fetch,
        create,
        accept,
        refuse,
        answerRecall,
        reset,
        resetMonths
    }
})
