<template>
    <BaseTable>
        <template #title>
            <slot name="title" />
        </template>
        <template #keys>
            <th>Date & heure</th>
            <th>Marchand</th>
            <th>Type</th>
            <th>Montant</th>
            <th>Statut</th>
        </template>
        <template #values>
            <template v-for="(value, i) in values" :key="i">
                <tr>
                    <td data-label="Date & heure">
                        {{ dayjs(value.date.creation).format('DD/MM/YYYY HH:mm') }}
                    </td>
                    <td data-label="Marchant">{{ value.label }}</td>
                    <td data-label="Type de payment">{{ formatedPayment[value.operationType] }}</td>
                    <td
                        data-label="Montant"
                        :class="{
                            'muted-text':
                                value.status === 'DECLINED' || value.status === 'CANCELED',
                            'warning-text': value.status === 'AUTHORIZED',
                            'success-text':
                                value.direction === 'CREDIT' && value.status === 'SETTLED'
                        }">
                        {{ value.direction === 'CREDIT' ? '+' : '-' }}
                        {{ value.amount.amount / 100 }}€
                    </td>
                    <td data-label="Status">
                        {{ formatedStatus[value.status] }}
                    </td>
                </tr>
            </template>
        </template>

        <template #footer>
            <slot name="footer" />
        </template>
    </BaseTable>
</template>

<script setup lang="ts">
import { defineAsyncComponent, useSlots } from 'vue'
import dayjs from 'dayjs'
import { type Operation } from '@ezio/features/agencies/types'

const slots = useSlots()

const BaseTable = defineAsyncComponent(() => import('@ezio/ui/content/BaseTable.vue'))

withDefaults(
    defineProps<{
        values: Operation[]
    }>(),
    {
        values: () => []
    }
)

const formatedPayment = {
    bankDirectDebit: 'Prélèvement',
    cardTransaction: 'Paiement carte',
    bankTransfer: 'Virement',
    bankTransfer_refund: 'Remboursement de virement'
}

const formatedStatus = {
    DECLINED: 'refusé',
    CANCELED: 'annulé',
    AUTHORIZED: 'en cours',
    SETTLED: ''
}
</script>

<style scoped lang="scss">
// .no-border {
//     &,
//     td {
//         border: 0;
//     }
//     tr {
//         border-bottom: 1px solid var(--c-color-border-soft);
//     }
// }

.success-text {
    color: var(--c-color-primary);
}

.muted-text {
    color: var(--c-color-text-soft);
    text-decoration: line-through;
}

.warning-text {
    color: var(--c-color-warning);
}
</style>
