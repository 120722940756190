export const alertCodes = {
    "card-w-1": {
        level: 'warning',
        description: "Retrait Distributeur Automatique de Billets (DAB)"
    },
    "card-w-2": {
        level: 'warning',
        description: "Paiement supérieur à 150€"
    },
    "card-t-1": {
        level: 'threat',
        description: "Paiements / retraits > 500 / 250€"
    },
    "card-t-2": {
        level: 'threat',
        description: "Paiements / retraits > 1000€"
    },
    "card-t-3": {
        level: 'threat',
        description: "3 retraits successifs sur 1/2 journée"
    },
    "sdde-w-1": {
        level: 'warning',
        description: "2ème prélèvement d’un même client pour dépôt",
        todo: "Un 2ème prélèvements pour dépôt d'un client n'est pas anormal mais il faut faire attention, le prélèvement n'est pas bloqué mais nous devons quand même vérifier le mandat de prélèvement SEPA du client final et vérifier que le justificatif de dépôt est signé par mesure de précaution",
    },
    "sdde-t-1": {
        level: 'threat',
        description: "Prélèvement d'un montant supérieur à 500€",
        todo: "Un prélèvement de plus de 500€ vient d'être effectué, le prélèvement est temporairement bloqué et nous devons vérifier le mandat de prélèvement SEPA du client final et vérifier que le justificatif de dépôt est signé",
    },
    "sdde-t-2": {
        level: 'threat',
        description: "Plus de 2 prélèvements pour dépôt de ce client",
        todo: "Plus de 2 prélèvements pour dépôt d'un client est anormal, le prélèvement est temporairement bloqué et nous devons vérifier le mandat de prélèvement SEPA du client final et vérifier que le justificatif de dépôt est signé",
    },
    "sctr-w-1": {
        level: 'warning',
        description: "Virement entrant > 500€",
        todo: "Un virement de ce montant ne devrait arriver que du compte de l'agence pour constituer son fond de roulement. Nous devons vérifier que c'est bien en rapport avec l’usage présumé (nb de clients / employés) . Si ce virment vient en remboursement d'une course alors bien vérifier que cette course n'est pas douteuse."
    },
    "scte-t-1": {
        level: 'threat',
        description: "",
        todo: "Cette opération n’étant normalement pas fréquente , nous pouvons vérifier chaque opération. le montant: Tous les virements font l’objet d’une validation par notre support. Nous vérifions que les montants remboursés sont en rapport avec les dépôts réalisés précédemment. Les virements externes ne peuvent excéder le montant du ou des dépôts. les montants supérieurs à 2000€ élevés doivent être particulièrement surveillés) faire l’objet d’une demande écrite à l’équipe support d’Ezio \
        le nom client: Il doit correspondre au nom figurant sur le mandat de prélèvement. Si ce n’est pas le cas, nous demandons un justificatif et le blocage n’est pas levé. \
        Le compte de destination: Il doit aussi correspondre au nom de l’entreprise (car elle peut changer de compte entre temps après avoir versé son dépôt ou un fonds de trésorerie.mandat \
        historique des virements: trop de virements qui se succèdent ? \
        historique des prélèvements: Si un prélèvement a eu lieu juste avant \
        les courses du client: Courses non justifiées / ou farfelues"
    },

}